const checkInputsOnSubmit = (e, form, lang) => {
  const checkedInputs = form.find('input[type=checkbox]:checked');
  let message = lang === 'fr' ? 'Veuillez cocher au moins une réponse par question' : 'Please check at least one answer per question'
  if (checkedInputs.length === 0) {
    e.preventDefault();
    e.stopPropagation();
    toastr.error(message);
  }
}

$(document).ready(() => {
  const form = $('input[name="step"][value="5"]').parents('form[id*="natural"]');
  form.on('submit', e => checkInputsOnSubmit(e, form, currentLocale));
});
