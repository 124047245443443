import log from 'stimulus_reflex/javascript/log.js';
import { formatter } from '../../../../../src/javascript/phoneNumberFormatter.js'

const showCardOnLoad = button => {
	const visibleCards = $('div[id^="beneficiary-card-"]:visible');

	const filledCards = $('div[id^="beneficiary-card-"]').not('#beneficiary-card-legal')
											.find('input:not([id$="__destroy"]):not([name*="_destroy]"]):not([type="hidden"]):not([type="radio"])')
											.filter(function() {return this.value.length !== 0})
											.parents('.card');
	filledCards.removeClass('d-none');

	const filledLegalCard = $('div[id^="beneficiary-card-legal"]')
													.find('input:not([id$="__destroy"]):not([name*="_destroy"]):not([type="hidden"]):not([type="radio"]):not([name*="last_name"]):not([name*="first_name"]):not([name*="email"]):not([name*="phone"])')
													.filter(function() {return this.value.length !== 0})
													.parents('.card');
	filledLegalCard.removeClass('d-none');


	if(visibleCards.length === 4) {
		button.addClass('d-none');
	}
}

const destroyCard = (target, button) => {
	const visibleCards = $('div[id^="beneficiary-card-"]:visible');

	target.parents('.card').addClass('d-none');

	if(visibleCards.length <= 4 &&  button.hasClass('d-none')) {
		button.removeClass('d-none');
	}
}

const showFirstHiddenCard = target => {
	const hiddenCard = $('div[id^="beneficiary-card-"]:hidden:not(#beneficiary-card-legal):first');
	// const hiddenCard = $('div[id^="beneficiary-card-"]:hidden:first');
	const visibleCards = $('div[id^="beneficiary-card-"]:visible');
	const destroyInput = hiddenCard.find('input[id$="__destroy"]');

	hiddenCard.removeClass('d-none');
	destroyInput.prop('checked', false);

	if(visibleCards.length === 3) {
		target.addClass('d-none');
	}
}

const showLegalRepresentativeCard = () => {
	const hiddenCard = $('div[id^="beneficiary-card-legal"]:hidden');
	const visibleCards = $('div[id^="beneficiary-card-legal"]:visible');
	const destroyInput = hiddenCard.find('input[id$="__destroy"]');

	hiddenCard.removeClass('d-none');
	destroyInput.prop('checked', false);
}

$(document).ready(() => {
  const phoneNumber = $(".beneficiaryPhoneNumber");
  const addBeneficiary = $("#addBeneficiary");
  const destroyBeneficiary = $('input[id^="users_profile_legal_beneficiaries_attributes_"][id$="__destroy"]');
  const hasDeclaredBeneficiaries = $('input[name="users_profile_legal[has_declared_beneficiaries]"]:checked').val();
  const hasLegalBeneficiary = $("#legal-representatives-question");

  showCardOnLoad(addBeneficiary); // Gets called on page load, display filled cards!(beneficiaries)

  if (phoneNumber) {
		phoneNumber.each((index) => {
			let phoneInput = document.querySelector(
				`#users_profile_legal_beneficiaries_attributes_${index}_phone_number`
			);
			let errorMessage = document.querySelector(
				`#beneficiary_phone_number_error_message_${index}`
			);
			formatter(phoneInput, errorMessage);
		});
  }

  addBeneficiary.on("click", (e) => showFirstHiddenCard($(e.target)));
  destroyBeneficiary.on("click", (e) => destroyCard($(e.target), addBeneficiary));

  if (hasDeclaredBeneficiaries === "true") {
    addBeneficiary.removeClass("d-none");
  } else {
    addBeneficiary.addClass("d-none");
  }

  if ($('input[name="users_profile_legal[has_declared_beneficiaries]"]:checked').val() === "true") {
    hasLegalBeneficiary.removeClass("d-none");
  } else {
		addBeneficiary.addClass('d-none'); // Optional: To remove after testing
	}

  if ( $('input[name="users_profile_legal[has_legal_representative_as_beneficiary]"]:checked').val() && $('input[name="users_profile_legal[has_declared_beneficiaries]"]:checked').val() === "true") {
    addBeneficiary.removeClass("d-none");
  }

	/**
	 * Used to display legal beneficiary card on page load if has_legal_benef is true and the page reloads(validations, empty inputs)
	*/
	if ($('input[name="users_profile_legal[has_legal_representative_as_beneficiary]"]:checked').val() === 'true') {
		$('#beneficiary-card-legal').removeClass('d-none');
		$('input[id="users_profile_legal_legal_beneficiary_attributes__destroy"]').prop("checked", false);
	}

  $('input[name="users_profile_legal[has_declared_beneficiaries]"]').on("change", () => {
      const selectedValue = $('input[name="users_profile_legal[has_declared_beneficiaries]"]:checked').val();
      if (selectedValue === "true") {
        if ($('input[name="users_profile_legal[has_legal_representative_as_beneficiary]"]:checked').val()) {
					addBeneficiary.removeClass('d-none');
				}
        hasLegalBeneficiary.removeClass("d-none");
      } else {
        addBeneficiary.addClass("d-none");
        hasLegalBeneficiary.addClass("d-none");
        $(".card").addClass("d-none");
      }
    }
  );

  $('input[name="users_profile_legal[has_legal_representative_as_beneficiary]"]').on("change", () => {
    addBeneficiary.removeClass("d-none");
    const value = $('input[name="users_profile_legal[has_legal_representative_as_beneficiary]"]:checked').val();
    if (value === "true") {
      showLegalRepresentativeCard();
      $('input[id="users_profile_legal_legal_beneficiary_attributes__destroy"]').prop("checked", false);
    } else if (value === "false") {
      $('input[id="users_profile_legal_legal_beneficiary_attributes__destroy"]').prop("checked", true);
      $("#beneficiary-card-legal").addClass("d-none");
    }
  });
});