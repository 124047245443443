$(document).ready(() => {
    const checker = $('#checkboxDocumentsRead');
    const link = $('.selectProfile');

    checker.on('change', () => checkDocumentsRead(checker, link))
    checker.on('change', () => hideTooltip(link));
    checkDocumentsRead(checker, link);

});

const checkDocumentsRead = (checkbox, link) =>  {
    if (checkbox.is(':checked')) {
        link.removeClass('disabled');
        link.prop("disabled", false);
    } else {
        link.addClass('disabled');
        link.prop("disabled", true);
    }
}

const hideTooltip = link => {
    const tt = $('.selectProfile').parent('[data-toggle="tooltip"]');
    tt.tooltip('hide');
}
