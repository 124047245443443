import { formatter } from '../../../../../src/javascript/phoneNumberFormatter.js'

$(document).ready(() => {
    const [legalInput, legalErrorMessage] = [
        document.querySelector("#users_profile_legal_phone_number"),
        document.querySelector("#users_profile_legal_phone_number_error_msg")
    ]

    if(legalInput) formatter(legalInput, legalErrorMessage);
});
