const noneInputHandler = input => {
    const otherInputs = input.parents('.form-group').find('input:not([id$="_none"])');
    const otherInputsDisabled = otherInputs.attr('disabled');

    if (otherInputsDisabled) {
        otherInputs.attr('disabled', false);
    } else {
        otherInputs.attr('disabled', true);
        otherInputs.prop('checked', false);
    }
};

$(document).ready(() => {
    const form = $('input[name="step"][value="8"]').parents('form');
    const noneIputs = form.find('input[id$="_none"]');
    const noneCheckedIputs = form.find('input[id$="_none"]:checked');
    noneInputHandler(noneCheckedIputs);
    noneIputs.on('change', e => noneInputHandler($(e.target)));

    const checkboxGroup = $("input:checkbox[id^='users_profile_natural_general_knowledge_on_investments_']");

    $('input[type="submit"]').on('click', () => {
        if (checkboxGroup.length) {
            if (checkboxGroup.is(":checked")) {
                checkboxGroup.prop('required', false);
            }
        }
    });
});
