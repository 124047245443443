import ApplicationController from './application_controller.js'

/* This is the custom StimulusReflex controller for the Legalstep Reflex.
 * Learn more at: https://docs.stimulusreflex.com
 */
export default class extends ApplicationController {

  static targets = ["input", "progress"];

  connect () {
    super.connect()
    let id      = this.element.dataset['id']
    let locale  = this.element.dataset['locale']

    console.log("SubscriptionStimulus Connect! ID", id)
    console.log("SubscriptionStimulus Connect! LOCALE", locale)

    $('select').select2()

  }

  nominative(event) {
    //event.preventDefault()
    // event.stopImmediatePropagation()

    console.log("NOMINATIVE EVENT:", event.target.dataset['nominative'])
    console.log('Nominative from Stimulus', this.element.dataset['nominative'])
    console.log('SubID from Stimulus', this.element.dataset['subid'])

    console.log('isActionCableConnectionOpen', this.isActionCableConnectionOpen())

    if (this.isActionCableConnectionOpen() ) {

      this.stimulate('Subscription#nominative', event.target).then( () => {
        console.log('The Reflex has benne finalized.')
        $('select').select2()
      }).catch( () => {
        console.log('CATCH ERROR!!!')
      })

    } else {
      setTimeout(function () {
        this.nominative(event)
      }.bind(this), 200);
    }

  }

  afterCreate(element, reflex) {
    console.log("\n\nFINALIZE_CREATE:\n\n", element, reflex)
    let id = element.dataset.id
    let locale = element.dataset.locale
    console.log("LOCALE", locale)
    document.location.href = "/user/subscriptions/"+id+"/edit?locale="+locale+"&step=2"
  }

  // finalizeCreate(element, reflex, noop, reflexId) {
  //   console.log("FINALIZED")
  // }


  finalizeReflex(anchorElement) {
    console.log("FinalizeREFELX")
  }

}
