import flatpickr from "flatpickr";

const fr = require("flatpickr/dist/l10n/fr.js").default.fr;

function flatpickrOptions(locale) {
  if (locale == 'en') {
    return { dateFormat: 'm/d/Y' };
  } else {
    return { dateFormat: 'd/m/Y', locale: fr };
  }
}

export function initFlatpickr() {
  const locale = new URLSearchParams(window.location.search).get('locale');

  flatpickr(".datepicker", {...flatpickrOptions(locale), allowInput: true});

  $('.datepicker').closest('form').on('submit', function() {
    if (locale == 'en') {
      $('.datepicker').each(function() {
        $(this).val(new Date($(this).val()).toLocaleDateString('fr'));
      });
    }
  });
}
