export function resetDestroyCheckBox() {
  const parentCards = $('.document');

  parentCards.find('input[id$="__destroy"]').prop('checked', false);
}

export function showCardOnLoad(button) {
  const visibleCards = $('div[id^="document-card-"]:visible');
  const filledCards = $('div[id^="document-card-"]')
                      .find('input:not([id$="__destroy"]):not([name*="_destroy]"]):not([type="hidden"])')
                      .filter(function() {return $(this).hasClass('filled')})
                      .parents('.document');
  filledCards.removeClass('d-none');

  if(visibleCards.length === 4) {
      button.addClass('d-none');
  }
}

export function destroyCard(target, button_selector) {
  const parentContainer = $(target).parents('.documents-container');
  const card = $(target.parents('.document'));
  const visibleCards = $('div[id^="document-card-"]:visible', parentContainer);
  const destroyInput = card.find('input[id$="__destroy', parentContainer)
  const button = $(button_selector, parentContainer);

  card.addClass('d-none');
  destroyInput.prop('checked', true)

  if(visibleCards.length <= 4 &&  button.hasClass('d-none')) {
    button.removeClass('d-none');
  }
}

export function showFirstHiddenCard(target) {
  const parentContainer = $(target).parents('.documents-container');
  const hiddenCard = $('div[id^="document-card-"]:hidden:first', parentContainer);
  const visibleCards = $('div[id^="document-card-"]:visible', parentContainer);
  const destroyInput = hiddenCard.find('input[id$="__destroy"]', parentContainer);

  hiddenCard.removeClass('d-none');
  destroyInput.prop('checked', false);

  if(visibleCards.length === 3) {
    target.addClass('d-none');
  }
}
