import ApplicationController from './application_controller.js'

/* This is the custom StimulusReflex controller for the Legalstep Reflex.
 * Learn more at: https://docs.stimulusreflex.com
 */
export default class extends ApplicationController {

  static targets = ["input", "progress"];

  connect() {

    super.connect()

    let id = this.element.dataset['id']
    let locale = this.element.dataset['locale']

    console.log("connect ELEMENT", this.element.dataset)
    console.log("SUSTAINStimulus Connect! ID", id)
    console.log("SUSTAINStimulus Connect! LOCALE", locale)

  }

  toggle_fund_inv(event) {

    // event.preventDefault()
    // event.stopImmediatePropagation()

    let attrib = this.element.dataset['attrib']
    let clearattrib = this.element.dataset['clearattrib']
    let value = this.element.dataset['value']
    let iddiv = this.element.dataset['iddiv']

    console.log("ELEMENT FUND_INV", this.element.dataset)

    console.log("attrib: ", attrib)
    console.log("clearAttrib: ", clearattrib)
    console.log("value: ", value)
    console.log("iddiv: ", iddiv)

    console.log('isActionCableConnectionOpen', this.isActionCableConnectionOpen())

    if (this.isActionCableConnectionOpen()) {

      this.stimulate('Sustain#toggle_fund_inv', event.target).then(() => {
        console.log('Stimulate Sustain#toggle_fund_inv')
      })

    }


  }

  toggle_adverse(event) {

    // event.preventDefault()
    // event.stopImmediatePropagation()

    let attrib = this.element.dataset['attrib']
    let clearattrib = this.element.dataset['clearattrib']
    let value = this.element.dataset['value']
    let iddiv = this.element.dataset['iddiv']

    console.log("attrib: ", attrib)
    console.log("clearAttrib: ", clearattrib)
    console.log("value: ", value)
    console.log("iddiv: ", iddiv)

    console.log('isActionCableConnectionOpen', this.isActionCableConnectionOpen())

    if (this.isActionCableConnectionOpen()) {

      this.stimulate('Sustain#toggle_adverse', event.target).then(() => {
        console.log('The Reflex has benne finalized.')
      })

    }

    // else {
    //   setTimeout(function () {
    //     this.nominative(event)
    //   }.bind(this), 200);
    // }

  }

  toggle_adverse(event) {

    // let attrib = this.element.dataset['attrib']
    // let clearattrib = this.element.dataset['clearattrib']
    // let value = this.element.dataset['value']
    // let iddiv = this.element.dataset['iddiv']

    // console.log("attrib: ", attrib)
    // console.log("clearAttrib: ", clearattrib)
    // console.log("value: ", value)
    // console.log("iddiv: ", iddiv)

    console.log('isActionCableConnectionOpen', this.isActionCableConnectionOpen())

    if (this.isActionCableConnectionOpen()) {

      this.stimulate('Sustain#toggle_adverse', event.target).then(() => {
        console.log('The Reflex has benne finalized.')
      })

    }

  }

  afterCreate(element, reflex) {
    console.log("\n\nFINALIZE_CREATE:\n\n", element, reflex)
    let id = element.dataset.id
    let locale = element.dataset.locale
    let short_flow = element.dataset.shortflow
    let profile = element.dataset.profile
    let step = parseInt(element.dataset.step)
    let fund_id = element.dataset.fundid
    let token = element.dataset.token

    let next_url = `/user/${profile}_profiles/${id}/edit?locale=${locale}&step=${step + 1}`

    console.log("fund_id", fund_id)
    console.log("short_flow", short_flow)
    console.log("locale", locale)

    if (fund_id !== undefined) {
      next_url += `&fund_id=${fund_id}`
    }

    if (short_flow !== undefined) {
      next_url += `&short_flow=${short_flow}`
    }

    if (token !== undefined) {
      next_url += `&token=${token}`
    }

    console.log("LOCATION", next_url)

    document.location.href = next_url

  }

  finalizeReflex(anchorElement) {
    console.log("FinalizeREFELX")
  }

}
