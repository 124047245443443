const computeEntryFeeInEuros = () => {
  const entryFee           = $('#subscription_entry_fee')
  const entryFeeInEuros    = $('#entry_fee_in_euros');

  const amount             = parseInt($('#subscription_amount').val());

  if (isNaN(amount)) return;

  const entryFeePercentage = parseFloat(entryFee.val()).toFixed(2);

  entryFee.val(entryFeePercentage);
  entryFeeInEuros.val(Math.round(amount * entryFeePercentage / 100))
};

const computeEntryFee = () => {
  const entryFee           = $('#subscription_entry_fee')
  const entryFeeInEuros    = parseInt($('#entry_fee_in_euros').val());

  const amount             = parseInt($('#subscription_amount').val());

  if (isNaN(amount)) return;

  let entryFeePerc         = entryFeeInEuros * 100 / amount
  const entryFeePercentage = parseFloat(entryFeePerc).toFixed(2);

  entryFee.val(entryFeePercentage);
};

const updateNominalValue = () => {
  const share = $('form input[id^="subscription_share_id_"]:checked')
  let shareUrl = share ? share.data('url') : undefined;

  if (!shareUrl) return;

  const subscriptionAmount = parseInt($('#subscription_amount').val());

  let shareUrlParts = shareUrl.split("?")
  shareUrlParts[0] += ".json"
  shareUrl = shareUrlParts.join("?")

  $.ajax({
    url: shareUrl,
    dataType: 'json',
    type: 'GET',
    success: data => {
      const shareNominalValue = data.share.nominal_value;
      const numberOfShares = Math.trunc(subscriptionAmount / shareNominalValue);
      //let text=$('label[for="subscription_amount"]').text();
      //let regex = text.match(/^.+ \([ei]n (.+)\)$/);
      let text=$('p[id="confirmation-text-model"]').text();
      let regex = text.match(/.+[0-9]\s(.+)\.\n$/);
      let currency_symbol = regex[1];
      $('#confirmation-text-model-shares-number').html(numberOfShares);
      $('span#confirmation-text-model-share-nominal-value').html(shareNominalValue + " " + currency_symbol.toLowerCase());
      $('#confirmation-text-model').change();
    }
  });
}

const updateConfirmationText = () => {
  const val       = $('form input#subscription_amount').val();
  const share     = $('form input[id^="subscription_share_id_"]:checked')
  const shareName = share.next('label').text().trim()

  if (val) {
    $('span#confirmation-text-model-amount').html(val);
    $('#confirmation-text-model').change();
  }

  if (shareName) {
    $('span#confirmation-text-model-share-name').html(shareName);
    $('#confirmation-text-model').change();
  }
}

const confirmationTextCheck = () => {
  if ($('textarea#confirmation_text')[0] && $('#confirmation-text-warning')[0]) {
    if ($('textarea#confirmation_text').val().trim() === $('#confirmation-text-model').text().trim()) {
      $('#confirmation-text-warning').addClass('d-none');
      $('form button[type="submit"]').attr('disabled', null);
      $('textarea#confirmation_text').removeClass('is-invalid');
      $('textarea#confirmation_text').addClass('is-valid');
    } else if ($('textarea#confirmation_text').val()) {
      $('#confirmation-text-warning').removeClass('d-none');
      $('form button[type="submit"]').attr('disabled', 'disabled');
      $('textarea#confirmation_text').addClass('is-invalid');
      $('textarea#confirmation_text').removeClass('is-valid');
    }
  }
}

$(document).ready(() => {
  computeEntryFeeInEuros();
  computeEntryFee();
  const amountInput = $('#subscription_amount');
  const entryFeePercentageInput = $('#subscription_entry_fee');
  const entryFeeInEurosInput    = $('#entry_fee_in_euros');

  amountInput.on('change', () => computeEntryFeeInEuros());
  entryFeePercentageInput.on('change', () => computeEntryFeeInEuros());
  entryFeeInEurosInput.on('change', () => computeEntryFee());

  updateConfirmationText();
  updateNominalValue();

  $('form input#subscription_amount').on('input', () => {
    updateConfirmationText();
    updateNominalValue();
  });

  $('form input[id^="subscription_share_id_"]').on('change', () => {
    updateConfirmationText();
    updateNominalValue();
  });

  $('textarea#confirmation_text').on('input', confirmationTextCheck);
  $('#confirmation-text-model').on('change', confirmationTextCheck);
});
