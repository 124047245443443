import ApplicationController from './application_controller'

export default class extends ApplicationController {

  static values = {
    legalselect: String
  }

  static targets = ["legal_pro_fieldset", "radioselect"]


  connect () {
    //super.connect()
    // add your code here, if applicable

    console.log('CONECT SIZE')
    console.log('legal select value', this.legalselectValue)

    if (this.legalselectValue == 'true') {
      this.legal_pro_fieldsetTarget.style.display='block'
    } else {
      this.legal_pro_fieldsetTarget.style.display='none'
    }

  }
  
  changed_radio(e) {
    e.preventDefault()
    //console.log(this.radioselectTargets)
    let checked_size_buttons = this.radioselectTargets.filter(e=>(e.checked && e.value == 'true')).length

    if (checked_size_buttons >= 2) {
      this.legal_pro_fieldsetTarget.style.display='block'
    } else {
      this.clearAllRadios(this.radioselectTargets)
      this.legal_pro_fieldsetTarget.style.display='none'
    }
  }

  clearAllRadios(radios) {

  }

}
