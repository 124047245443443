const toggleWarningMessage = () => {
  const radios = $("input[id^='users_profile_legal_investment_horizon_']");

  radios.on('change', () => {
    let checkedRadio = $("input[id^='users_profile_legal_investment_horizon_']:checked");

    if (checkedRadio.val() != 'more_than_10y') {
      $('#short_term_warning').removeClass('d-none');
    } else {
      $('#short_term_warning').addClass('d-none');
    }
  })
};

const checkInputsOnSubmit = (e, form, lang) => {
  const checkedInputs = form.find('input[type=checkbox]:checked');
  let message = lang === 'fr' ? 'Veuillez cocher au moins une réponse par question' : 'Please check at least one answer per question'
  if (checkedInputs.length === 0) {
    e.preventDefault();
    e.stopPropagation();
    toastr.error('Veuillez cocher au moins une réponse par question');
  }
}

$(document).ready(() => {
  toggleWarningMessage();
  const form = $('input[name="step"][value="5"]').parents('form[id*="legal"]');
  form.on('submit', e => checkInputsOnSubmit(e, form, currentLocale));
});
