const validateForm = () => {
  let newProfileDocumentForm = $("form#new-profile-document-form", ".modal#new-profile-document");
  let $profileDocumentTypeInput = $("#users_profiles_document_name", newProfileDocumentForm);
  let $profileDocumentFileInput = $("#profile-document-file-input", newProfileDocumentForm);
  let $profileDocumentExpirationDateInput = $("#profile-document-expiration-date-input", newProfileDocumentForm);

  let $documentTypeMissingError = $("#profile-document-type-missing-error");
  let $documentMissingError = $("#profile-document-missing-error");
  let $expirationDateMissingError = $("#profile-document-expiration-date-missing-error");
  let $expirationDateExpiredError = $("#profile-document-expiration-date-expired-error");

  let errorsCount = 0;
  let documentType = $profileDocumentTypeInput.val();
  let expirationDate = $profileDocumentExpirationDateInput.val();

  $documentTypeMissingError.addClass("d-none");
  $documentMissingError.addClass("d-none");
  $expirationDateMissingError.addClass("d-none");
  $expirationDateExpiredError.addClass("d-none");

  if (documentType === "") {
    console.log($documentTypeMissingError);
    errorsCount += 1;
    $documentTypeMissingError.removeClass("d-none");
  } else if (documentType === "front_identity") {

    if (expirationDate === "") {
      errorsCount += 1;
      $expirationDateMissingError.removeClass("d-none");
    } else if (Date.parse(expirationDate) < (new Date())) {
      errorsCount += 1;
      $expirationDateExpiredError.removeClass("d-none");
    }
  }

  if ($profileDocumentFileInput.val() === "") {
    errorsCount += 1;
    $documentMissingError.removeClass("d-none");
  }

  return errorsCount === 0;
};

$(document).ready(() => {
  const $newProfileDocumentModal = $(".modal#new-profile-document");
  const $newProfileDocumentForm = $("form#new-profile-document-form", $newProfileDocumentModal);
  const $profileDocumentTypeInput = $("#users_profiles_document_name", $newProfileDocumentForm);
  const $profileDocumentExpirationDateRow = $("#profile-document-expiration-date-row", $newProfileDocumentForm);
  const $profileDocumentExpirationDateInput = $("#profile-document-expiration-date-input", $profileDocumentExpirationDateRow);
  const $profileDocumentFileInput = $("#profile-document-file-input");

  $profileDocumentFileInput.on("change", function() {
    if ($profileDocumentTypeInput.val() === "front_identity") {
      $profileDocumentExpirationDateRow.removeClass("d-none");
    }
  });

  $newProfileDocumentModal.on("hidden.bs.modal", function() {
    $newProfileDocumentForm.trigger("reset");
    $profileDocumentExpirationDateRow.addClass("d-none");

    $(".profile-document-error", $newProfileDocumentForm).addClass("d-none");
  });

  $profileDocumentTypeInput.on("change", function() {
    if ($(this).val() === "front_identity") {
      $profileDocumentExpirationDateRow.removeClass("d-none");
    } else {
      $profileDocumentExpirationDateRow.addClass("d-none");
      $profileDocumentExpirationDateInput.val("");
    }
  });

  $newProfileDocumentForm.on("submit", function(e) {
    if (!validateForm()) {
      e.preventDefault();
      e.stopPropagation();
    }
  });
});
