const overseas_fr = ['FR', 'GP', 'MQ', 'GY', 'RE', 'YT', 'PF'];

const toggleWarningMessage = input => {
    const trueInput = $(`#users_profile_natural_${input}_true`);
    const falseInput = $(`#users_profile_natural_${input}_false`);

    trueInput.on('change', () => {
        $(`#${input}_warning`).removeClass('d-none');
        trueInput.parent('.form-group').addClass('has-warning');
    });

    falseInput.on('change', () => {
        $(`#${input}_warning`).addClass('d-none');
        falseInput.parent('.form-group').removeClass('has-warning');
    });
};

const updateNifPlaceholder = target => {
    const nifInput = target.parents('.row:first').find("input[id^='users_profile_natural_fatca_nif_number_']");

    if (overseas_fr.includes(target.val())) {
        nifInput.prop('placeholder', 'NIF (facultatif)');
    }
}

$(document).ready(() => {
    const fiscalCountryInputs = $("select[id^='users_profile_natural_fatca_'][id$='_fiscal_country']");

    fiscalCountryInputs.on('change', e => updateNifPlaceholder($(e.target)));
    toggleWarningMessage('is_politically_exposed_person');
});
