import { showInvalidFeedback } from '../../../../application';
import { showCheckedInputsOnLoad } from '../../../../application';
import { showNumberInputsOnLoad } from '../../../../application';

const entityDictionary = {
    true: 'input[id^="users_profile_legal_entity_category_"]',
    false: 'input[id^="users_profile_legal_entity_fatca_"]'
}

const americanLawEntityHandler = (radio, value) => {
    const boolVal = value == 'true';
    const showInput = $(entityDictionary[boolVal]);
    const hideInput = $(entityDictionary[!boolVal]);

    showInput.parents('.row:first').removeClass('d-none');
    hideInput.parents('.row:first').addClass('d-none');
    hideInput.prop('checked', false);
};

const reportingFiHandler = prop => {
    const giinNumberParent = $('#users_profile_legal_giin_number').parents('.row:first')

    if(prop === 'show') {
        giinNumberParent.removeClass('d-none');
    } else {
        giinNumberParent.addClass('d-none');
        giinNumberParent.find('input').val('');
    }
};

$(document).ready(() => {
    const americanLawEntityTrue = $('#users_profile_legal_american_law_entity_true');
    const americanLawEntityFalse = $('#users_profile_legal_american_law_entity_false');
    const reportingFi = $('#users_profile_legal_entity_fatca_reporting_fi');
    const giinNumber = $('#users_profile_legal_giin_number')
    const entityCategory = $('input[id^="users_profile_legal_entity_category_"]');
    const entityFatca = $('input[id^="users_profile_legal_entity_fatca_"]');
    const nonReportingFi = reportingFi
                            .parents('.row:first')
                            .find('input:not([id="users_profile_legal_entity_fatca_reporting_fi"])');

    showInvalidFeedback();
    showNumberInputsOnLoad(giinNumber);
    showCheckedInputsOnLoad($.merge(entityCategory, entityFatca));
    reportingFi.on(
        'change',
        () => reportingFiHandler('show')
    );
    nonReportingFi.on(
        'change',
        () => reportingFiHandler('hide')
    )
    americanLawEntityTrue.on(
        'change',
        e => americanLawEntityHandler($(e.target), $(e.target).val())
    );
    americanLawEntityFalse.on(
        'change',
        e => americanLawEntityHandler($(e.target), $(e.target).val())
    );
});
