const noneInputHandler = input => {
    const otherInputs = input.parents('.form-group').find('input:not([id$="_none"])');
    const otherInputsDisabled = otherInputs.attr('disabled');

    if (otherInputsDisabled) {
        otherInputs.attr('disabled', false);
    } else {
        otherInputs.attr('disabled', true);
        otherInputs.prop('checked', false);
    }
};

const checkInputsOnChange = form => {
    const checkedInputs = form.find('input:checked');

    if (checkedInputs.length > 0) {
        form.unbind('submit');
    } else {
        form.on('submit', e => checkInputsOnSubmit(e, $(e.target)));
    }
};

const checkInputsOnSubmit = (e, form, lang) => {
    const checkedInputs = form.find('input:checked');
    let message = lang === 'fr' ? 'Veuillez cocher au moins une réponse par question' : 'Please check at least one answer per question'
    if (checkedInputs.length === 0) {
        e.preventDefault();
        e.stopPropagation();
        toastr.error(message);
    }
}

$(document).ready(() => {
    const form = $('input[name="step"][value="8"]').parents('form');
    const noneIputs = form.find('input[id$="_none"]');
    const noneCheckedIputs = form.find('input[id$="_none"]:checked');
    const firstNoneIputs = form.find('#users_profile_legal_financial_products_knowledge_none');
    const inputs = form.find('input');

    noneInputHandler(noneCheckedIputs);
    form.on('submit', e => checkInputsOnSubmit(e, form, currentLocale));
    inputs.on('change', () => checkInputsOnChange(form))
    noneIputs.on('change', e => noneInputHandler($(e.target)));
});
