const toggleTextInput = (checkBox, input) => {
  const trueCheckBox = $(`#users_profile_natural_${checkBox}_true`);
  const falseCheckBox = $(`#users_profile_natural_${checkBox}_false`);

  trueCheckBox.on('change', () => {
    input.removeClass('d-none');
    $('#profile_minimum_yearly_savings_amount-invalid-feedback').removeClass('d-none');
  });

  falseCheckBox.on('change', () => {
    input.addClass('d-none');
    $('#profile_minimum_yearly_savings_amount-invalid-feedback').addClass('d-none');
    input.val("");
  });
};

const currencyConverter = { 'EUR': '€', 'CHF': 'CHF', 'USD': '$', 'GBP': '£' };

const assetsFields = [
  'funds_origin_revenue',
  'funds_origin_land_transfer',
  'funds_origin_shares_transfer',
  'funds_origin_succession',
  'funds_origin_stock_options',
  'funds_origin_other'
];

const initPatrimonyFields = () => {
  const eurosInput = $('#users_profile_natural_funds_origin_unit_euros');
  const percentageInput = $('#users_profile_natural_funds_origin_unit_percentage');

  if (percentageInput.is(':checked')) {
    $.each(assetsFields, function (index, field) {
      updatePlaceholderUnit($(`input#users_profile_natural_${field}`), '%');
    });
  } else if (eurosInput.is(':checked')) {
    calculatePercentages();
  }

  $.each(assetsFields, function (index, field) {
    $(`input#users_profile_natural_${field}`).change(function () {
      if (eurosInput.is(':checked')) {
        calculatePercentages();
      }
    });
  });

  eurosInput.click(function () {
    currencySelected = $('#select2-users_profile_natural_currency-container').text()
    currency_symbol = currencyConverter[currencySelected];
    resetPatrimonyFields(currency_symbol);
    calculatePercentages();
  });

  percentageInput.click(function () {
    resetPatrimonyFields('%');

    $.each(assetsFields, function (index, field) {
      $(`span#${field}_percentage`).addClass('d-none');
    });
  });
};

const resetPatrimonyFields = (unit) => {
  $.each(assetsFields, function (index, field) {
    updatePlaceholderUnit($(`input#users_profile_natural_${field}`), unit);
    $(`input#users_profile_natural_${field}`).val('');
    $(`span#${field}_percentage`).text('');
  });
};

const updatePlaceholderUnit = (input, unit) => {
  let placeholder = input.attr('placeholder').replace(/[€$£%]|CHF/g, unit);
  input.attr('placeholder', placeholder);
};

const calculatePercentages = () => {
  let sum = 0;

  $.each(assetsFields, function (index, field) {
    let amount = parseFloat($(`input#users_profile_natural_${field}`).val());

    if (!isNaN(amount)) {
      sum += amount;
    }
  });

  $.each(assetsFields, function (index, field) {
    let percentage = ((parseFloat($(`input#users_profile_natural_${field}`).val()) / sum) * 100).toFixed(2);

    if (!isNaN(percentage)) {
      $(`span#${field}_percentage`).text(`${percentage} %`);
    } else {
      $(`span#${field}_percentage`).text('');
    }

    $(`span#${field}_percentage`).removeClass('d-none');
  });
};

$(document).ready(() => {
  const input = $('#users_profile_natural_minimum_yearly_savings_amount');

  if ((input.val() && input.val().length > 0) || $('#users_profile_natural_has_savings_true').val() == 'true') {
    input.removeClass('d-none');
  }

  toggleTextInput('has_savings', input);
  initPatrimonyFields();
});
