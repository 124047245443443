$(document).ready(() => {
  const form = $('input[name="step"][value="4"]').parents('form[id*="natural"]');

  professionParent(form);
  partnerParent(form);

  $('#users_profile_natural_professional_situation').on('change', () => {
    professionParent(form);
  });

  $('#users_profile_natural_family_situation').on('change', () => {
    partnerParent(form);
  });
});

const professionParent = (form) => {
  var profession_parent = form.find('#users_profile_natural_profession').parents('.form-group');

  if ($('#users_profile_natural_professional_situation option:selected').val() === 'active') {
    profession_parent.removeClass('d-none');
  } else {
    profession_parent.addClass('d-none');
    $('#users_profile_natural_profession').val("");
  }
}

const partnerParent = (form) => {
  var partner_parent = form.find('#users_profile_natural_partner_consent').parents('.form-group');
  var spouse_parent = form.find('#users_profile_natural_spouse_consent').parents('.form-group');

  if ($('#users_profile_natural_family_situation option:selected').val() === 'pacs') {
    partner_parent.removeClass('d-none');
  } else {
    partner_parent.addClass('d-none');
    $('#users_profile_natural_partner_consent').prop('checked', false);
  }

  if ($('#users_profile_natural_family_situation option:selected').val() === 'maried') {
    spouse_parent.removeClass('d-none');
  } else {
    spouse_parent.addClass('d-none');
    $('#users_profile_natural_spouse_consent').prop('checked', false);
  }
}
