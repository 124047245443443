$(document).ready(() => {
    const firstNameInput = document.querySelector('input[name="user[first_name]"]');
    const lastNameInput = document.querySelector('input[name="user[last_name]"]');

    const emailInput = document.querySelector('input[name="user[email]"]');
    const phoneInput = document.querySelector('input[name="user[phone_number]"]');

    const radioType1 = document.querySelector('#user_profiles_attributes_0_type_usersprofilenatural');
    const radioType2 = document.querySelector('#user_profiles_attributes_0_type_usersprofilelegal');

    const submitButton = document.querySelector('#create-user-submit-button');

		const inputs = [firstNameInput, lastNameInput, emailInput, phoneInput];
		const types = [radioType1, radioType2];

    const inputsEmpty = () => {
			const areInputsEmpty = inputs.every(input => input.value.trim());
			const areTypesUnchecked = types.some(type => type.checked);
			return !areInputsEmpty || !areTypesUnchecked;
    };

    const handleInputChange = () => {
      submitButton.disabled = inputsEmpty();
    };

    const elementsToMonitor = [firstNameInput, lastNameInput, emailInput, phoneInput, radioType1, radioType2];

    elementsToMonitor.forEach(element => {
      if (element) element.addEventListener(element.tagName.toLowerCase() === 'input' ? 'input' : 'change', handleInputChange)
    })

    const personRadioButtons  = document.querySelectorAll('[name="user[profiles_attributes][0][type]"]');
    const legalLable = document.querySelector('#legalLabel');

    personRadioButtons.forEach(button => {
      button.addEventListener("change", () => {
        if (button.value == 'Users::Profile::Legal') {
          legalLable.style.visibility = 'visible'
        } else {
          legalLable.style.visibility = 'hidden'
        }
      })
    })
});