import ApplicationController from './application_controller.js'
import { Modal } from "bootstrap";

/* This is the custom StimulusReflex controller for the Legalstep Reflex.
 * Learn more at: https://docs.stimulusreflex.com
 */
export default class extends ApplicationController {


  afterCreate(element, reflex) {
    console.log("\n\nFINALIZE_CREATE:\n\n", element.dataset.id, reflex)

    let id = element.dataset.id
    let locale = element.dataset.locale
    let short_flow = element.dataset.shortflow
    let step = parseInt(element.dataset.step)
    let fund_id = element.dataset.fundid
    let token = element.dataset.token

    let next_url = `/user/legal_profiles/${id}/edit?locale=${locale}&step=${step + 1}`

    console.log("fund_id", fund_id)
    console.log("short_flow", short_flow)

    if (fund_id !== undefined) {
      next_url += `&fund_id=${fund_id}`
    }

    if (short_flow !== undefined) {
      next_url += `&short_flow=${short_flow}`
    }

    if (token !== undefined) {
      next_url += `&token=${token}`
    }

    console.log("LOCATION", next_url)

    document.location.href = next_url

  }

  pro_by_size(event) {

    console.log('isActionCableConnectionOpen', this.isActionCableConnectionOpen())

    if (this.isActionCableConnectionOpen()) {

      this.stimulate('Legalstep#pro_by_size', event.target).then(() => {
        console.log('The Reflex has benne finalized.')
      })

    }

  }

  finalizeReflex(anchorElement) {
    console.log("FinalizeREFELX")
    // users_profile_legal_request_pro_category_true
    const proTrueCheckBox = document.querySelector('#users_profile_legal_request_pro_category_true');
    if ( proTrueCheckBox && proTrueCheckBox.checked ) {
      const proCatModalElement = document.querySelector('#proCatModal');
      const proCatModal = new Modal(proCatModalElement);
      proCatModal.show();
    }
  }

}
