import { showNumberInputsOnLoad } from '../../../../application';

const showOtherInput = input => {
  const otherInputParent = input.parents('.row').next('.row');

  otherInputParent.removeClass('d-none');
};

const hideOtherInput = input => {
  const otherInputParent = input.parents('.row').next('.row');
  const otherInput = otherInputParent.find('input[id^="users_profile_legal_total_"][id$="_other"]');

  otherInputParent.addClass('d-none');
  otherInput.val('');
};


function currency_change_step6() {

  var placeholders = $('*[placeholder="Select Country"]');

  const select_currency = {
    'fr': {
      'CHF': { 'code': 'CHF', 'name': 'Francs Suisses', 'apost': 'de francs suisses' },
      'EUR': { 'code': '€', 'name': 'Euros', 'apost': 'd\'euros' },
      'GBP': { 'code': '£', 'name': 'Livre sterling', 'apost': 'de livres sterling' },
      'USD': { 'code': '$', 'name': 'Dollar U.S.', 'apost': 'de dollars U.S.' }
    },
    'en': {
      'CHF': { 'code': 'CH', 'name': 'CHF', 'apost': 'CHF' },
      'EUR': { 'code': '€', 'name': 'Euros', 'apost': 'euros' },
      'GBP': { 'code': '£', 'name': 'Pounds sterling', 'apost': 'pounds of sterling' },
      'USD': { 'code': '$', 'name': 'U.S. dollar', 'apost': 'U.S. dollars' }
    }
  };

  const placeholder_text = {
    'fr': 'Précisez montant en ',
    'en': 'Specify in '
  }

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const locale = urlParams.get('locale');

  $('#users_profile_legal_currency, #users_profile_natural_currency').on('select2:select', function (e) {
    var currency = e.params.data.text;

    //console.log(currency.text);

    var aposts = $('.currency_apost');
    var codes = $('.currency_code');
    var attacheds = $('.currency_attached');
    var placeholders = $('input[placeholder*="Précisez montant en"], input[placeholder*="Specify in"]').filter(function () {
      return this.placeholder.match(/[^%]$/);
    });
    if (aposts) {
      $(aposts).text(select_currency[locale][currency].apost);
    }

    if (codes) {
      $(codes).text(select_currency[locale][currency]['code']);
    }

    if (attacheds) {
      $(attacheds).text(select_currency[locale][currency]['attached']);
    }

    if (placeholders) {
      placeholders.attr("placeholder", placeholder_text[locale] + select_currency[locale][currency]['code']);
    }

  });
}

$(document).ready(() => {
  const form = $('input[name="step"][value="6"]').parents('form[id*="legal"]');
  const showRadio = form.find('input[id$="_more_than_1_b"]');
  const hideRadio = form.find('input:not([id$="_more_than_1_b"]):not([id$="_other"])');
  const numberInputs = form.find('input[id$="_other"]');

  showNumberInputsOnLoad(numberInputs);
  hideRadio.on('change', e => hideOtherInput($(e.target)));
  showRadio.on('change', e => showOtherInput($(e.target)));

  currency_change_step6();

})
