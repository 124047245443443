import ApplicationController from './application_controller'

export default class extends ApplicationController {

  static values = {
    optionselect: String
  }

  static targets = ["radioselect"]

  connect () {
    //super.connect()
    // add your code here, if applicable

    console.log('CONECT SIZE')
    console.log(this.sizeselectValue)

  }
  
  // changed_radio(e) {
  //   e.preventDefault()
  //   //console.log(this.radioselectTargets)
  //   let checked_size_buttons = this.radioselectTargets.filter(e=>(e.checked && e.value == 'true')).length

  //   if (checked_size_buttons < 2) {
  //     this.legal_cat_fieldsetTarget.style.display='block'
  //   } else {
  //     this.clearAllRadios(this.radioselectTargets)
  //     this.legal_cat_fieldsetTarget.style.display='none'
  //   }
  // }

  // clearAllRadios(radios) {

  // }

}
