const toggleWarningMessage = () => {
  const radios = $("input[id^='users_profile_natural_investment_horizon_']");

  radios.on('change', () => {
    let checkedRadio = $("input[id^='users_profile_natural_investment_horizon_']:checked");

    if (checkedRadio.val() != 'more_than_10y') {
      $('#short_term_warning').removeClass('d-none');
    } else {
      $('#short_term_warning').addClass('d-none');
    }
  })
};

$(document).ready(() => {
  toggleWarningMessage();
});
