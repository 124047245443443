// TODO: Add language detection to change the button's text in `updateSubmitButton()`.
$(document).ready(() => {
  const nomineeNetworksSelect = document.querySelector(".nominee-networks-selector");
  const submitButton = document.querySelector('form button[type="submit"]');
  const submitButtonOriginalText = submitButton.querySelector("span").innerText;
  // const submitButton = document.querySelector('#insurance-member-step0-submit');
  // const submitButtonOriginalText = submitButton ? submitButton.querySelector("#insurance-member-step0-submit-text").innerText : null;
  const sideBarSteps = document.querySelectorAll(".flow-list.insurance-member .d-block");
  const withNomineeRadioButtons = document.querySelectorAll('input[type="radio"][name="subscription[with_nominee]"]');
  const sharesRadioButtons = document.querySelectorAll('.custom-radio input[type="radio"][name="subscription[share_id]"]');

  const toggleStepsVisibilityFromTheSideNav = (steps, isWithNominee) => {
    steps.forEach((step) => {
      if (isWithNominee) {
        step.style.visibility = "hidden"; // Use `visibility` instead of display, because `display` is set from `d-block` class.
      } else {
        step.style.visibility = "";
      }
    });
  };

  const updateSubmitButton = (radioButton) => {
    let newInnerText;
    let withNominee = false;
    if (radioButton.checked && radioButton.value === "true") {
      if (submitButtonOriginalText.toLowerCase() === "suivant") {
        newInnerText = "Enregistrer";
      } else {
        newInnerText = "Save";
      }
      submitButton.querySelector("span").innerText = newInnerText; // Change the inner text of the button
      nomineeNetworksSelect.classList.remove("d-none"); // Show select element
      withNominee = true;
    } else {
      submitButton.querySelector("span").innerText = submitButtonOriginalText;
      nomineeNetworksSelect.classList.add("d-none"); // Hide select element
      withNominee = false;
    }
    toggleStepsVisibilityFromTheSideNav(sideBarSteps, withNominee); // hide step 1 and 2 from the side !!!!
  };

  withNomineeRadioButtons.forEach((radioButton) => {
    radioButton.addEventListener("change", () =>
      updateSubmitButton(radioButton),
    );
  });

  // check if nominee is checked and display the select element
  if (withNomineeRadioButtons[0] && withNomineeRadioButtons[0].checked == true) {
    // With nominee is checked
    updateSubmitButton(withNomineeRadioButtons[0]);
    nomineeNetworksSelect.classList.remove("d-none");
    toggleStepsVisibilityFromTheSideNav(sideBarSteps, true);
  }

  // Disable shares options
  sharesRadioButtons.forEach((radioButton) => {
    radioButton.disabled = true;
  });
});
