import ApplicationController from './application_controller'

export default class extends ApplicationController {

  static values = {
    natureselect: String
  }

  static targets = ["nature_categorization", "size_cat_fieldset"]

  connect () {
    //super.connect()
    // add your code here, if applicable
    console.log('CONECT WEBPCKER')
    console.log(this.natureselectValue)
    //this.outputTarget.textContent = 'Hello, Stimulus!'
    if (this.natureselectValue == 'none') {
      this.size_cat_fieldsetTarget.style.display='block'
    } else {
      this.size_cat_fieldsetTarget.style.display='none'
    }

  }
  
  changed_nature(e) {
    e.preventDefault()
    console.log(e.target.value)
    if (e.target.value=='none'){
      this.size_cat_fieldsetTarget.style.display='block'
    } else {
      this.clearAllRadios(this.size_cat_fieldsetTarget)
      this.size_cat_fieldsetTarget.style.display='none'
    }
  }

  // changed_size(e) {
  //   e.preventDefault()
  //   console.log(e.target.value)

  // }

  clearAllRadios(target) {
    //console.log("clearAllRadios", target)
    let radios = target.querySelectorAll("[id*='legal_balance_sheet_greater_than_20_m'],[id*='legal_equity_greater_than_2_m'], [id*='legal_turnover_greater_than_40_m']")
    radios.forEach(r=> r.checked=false)
  }


}
