import intlTelInput from 'intl-tel-input';
import telUtils from 'intl-tel-input/build/js/utils';
import 'intl-tel-input/build/css/intlTelInput.css';


export const formatter = (input, errorMessage) => {
    const reset = errorMsg => {
        errorMsg.classList.add('d-none');
        $('input[type="submit"]').removeAttr('disabled');
    };

    const iti = intlTelInput(input, {
        preferredCountries: ['fr'],
        utilsScript: telUtils,
        formatOnDisplay: false
    });

    const updatePhoneNumber = input => {
        const intNumber = iti.getNumber();
        input.value = intNumber;
    }

    input.addEventListener('change', () => {
        updatePhoneNumber(input);
    });

    input.addEventListener('countrychange', () => {
        updatePhoneNumber(input);
    });

    input.addEventListener('blur', () => {
        reset(errorMessage);
        if (input.value.trim()) {
            if (!iti.isValidNumber()) {
                errorMessage.classList.remove('d-none');
                $('input[type="submit"]').attr('disabled', 'disabled');
            }
        }
    });

    input.addEventListener('load', reset(errorMessage));
    input.addEventListener('change', reset(errorMessage));
    input.addEventListener('keyup', reset(errorMessage));
}
