import { formatter } from '../../src/javascript/phoneNumberFormatter.js'

$(document).ready(() => {
    const [naturalInput, naturalErrorMessage] = [
        document.querySelector("#user_phone_number"),
        document.querySelector("#user_phone_number_error_msg")
    ]

    if(naturalInput) formatter(naturalInput, naturalErrorMessage);
});
