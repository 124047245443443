export function stopVideoOnClose() {
  $('#videoModal').on('hidden.bs.modal', function () {
    $("#videoModal video").attr("src", $("#videoModal video").attr("src"));
  });
}

export function openWarningModal(){
  if (document.body.contains(document.getElementById('warningModal'))) {
    $('#warningModal').modal('show');
  }
}
