import ApplicationController from './application_controller'

export default class extends ApplicationController {

  static values = {
    sizeselect: String,
    balancesheetgreaterthan20m: String,
    equitygreaterthan2m: String,
    turnovergreaterthan40m: String
  }

  static targets = ["legal_cat_fieldset", "radioselect", "balance_sheet_value", "equity_value", "turnover_value"]


  connect () {
    //super.connect()
    // add your code here, if applicable

    console.log('CONECT SIZE')
    console.log('this.sizeselectValue', this.sizeselectValue)
    console.log('balance', this.balancesheetgreaterthan20mValue)
    console.log('equity', this.equitygreaterthan2mValue)
    console.log('turnOver', this.turnovergreaterthan40mValue)

    if (this.sizeselectValue == 'true') {
      this.legal_cat_fieldsetTarget.style.display='none'
    } else {
      this.legal_cat_fieldsetTarget.style.display='block'
    }


    if (this.balancesheetgreaterthan20mValue == 'true') {
      this.balance_sheet_valueTarget.style.display='block'
    } else {
      this.balance_sheet_valueTarget.style.display='none'
    }

    if (this.equitygreaterthan2mValue== 'true') {
      this.equity_valueTarget.style.display='block'
    } else {
      this.equity_valueTarget.style.display='none'
    }

    if (this.turnovergreaterthan40mValue== 'true') {
      this.turnover_valueTarget.style.display='block'
    } else {
      this.turnover_valueTarget.style.display='none'
    }

  }
  
  changed_radio(event) {
    event.preventDefault()
    console.log("CHANGED", this.radioselectTarget)
    console.log("TAGET", event.target)
    let checked_size_buttons = this.radioselectTargets.filter(e=>(e.checked && e.value == 'true')).length

    if (checked_size_buttons >= 2) {
      this.legal_cat_fieldsetTarget.style.display='none'
    } else {
      this.legal_cat_fieldsetTarget.style.display='block'
    }

    this.openCloseField(event.target)

  }

  clearAllRadios(target) {
    target.forEeach( element => element.checked = false )
  }

  openCloseField(input) {
    console.log("SWITCH", input.id)
    switch(input.id) {
      case 'users_profile_legal_balance_sheet_greater_than_20_m_true':
        this.balance_sheet_valueTarget.style.display='block'
        break
      case 'users_profile_legal_balance_sheet_greater_than_20_m_false':
        this.balance_sheet_valueTarget.style.display='none'
        console.log(this.balance_sheet_valueTarget)
        break
      case 'users_profile_legal_equity_greater_than_2_m_true':
        this.equity_valueTarget.style.display='block'
        break
      case 'users_profile_legal_equity_greater_than_2_m_false':
        this.equity_valueTarget.style.display='none'
        break
      case 'users_profile_legal_turnover_greater_than_40_m_true':
        this.turnover_valueTarget.style.display='block'
        break
      case 'users_profile_legal_turnover_greater_than_40_m_false':
        this.turnover_valueTarget.style.display='none'
    }
  }
}
