$(document).ready(() => {


  const birthdayDatepicker = $('#users_profile_natural_birth_date');
  const initParentsFieldSet = $("#parents");


  // INIT DISPLAY

  initDate     = birthdayDatepicker.val();

  if (initDate !== '') {

    initBirth    = convertDateToISO(initDate);
    initBirthDay = new Date(initBirth);
    initAge      = getAge(initBirthDay);

    if (initAge < 18) {
      console.log( "< 18")
      initParentsFieldSet.removeClass('d-none'); // test if d-none exist and set
    }

  }

  //EVENT CHANGE BIRTHEDATE

  birthdayDatepicker.on('change', (e) => {
    e.preventDefault();  

    var date     = e.target.value;
    console.log(date);
    var birth    = convertDateToISO(date);
    console.log(birth);
    var birthDay = new Date(birth);
    var age      = getAge(birthDay);
    console.log("age", age);

    var parentsFieldSet = $("#parents");

    firstName0   = $("#users_profile_natural_parents_attributes_0_first_name");
    lastName0    = $("#users_profile_natural_parents_attributes_0_last_name");
    email0       = $("#users_profile_natural_parents_attributes_0_email");
    phoneNumber0 = $("#users_profile_natural_parents_attributes_0_phone_number");

    firstName1   = $("#users_profile_natural_parents_attributes_1_first_name");
    lastName1    = $("#users_profile_natural_parents_attributes_1_last_name");
    email1       = $("#users_profile_natural_parents_attributes_1_email");
    phoneNumber1 = $("#users_profile_natural_parents_attributes_1_phone_number");

    // SELECT2
    kindSelect0 = $('#users_profile_natural_parents_attributes_0_kind');
    kindSelect1 = $('#users_profile_natural_parents_attributes_1_kind');
  
    // ACTIONS//

    if (age < 18) {
      console.log( "< 18")
      parentsFieldSet.removeClass('d-none'); // test if d-none exist and set
    } else {
      console.log("> 18")

      firstName0.val("");
      lastName0.val("");
      email0.val("");
      phoneNumber0.val("");

      firstName1.val("");
      lastName1.val("");
      email1.val("");
      phoneNumber1.val("");

      kindSelect0.val(null).trigger('change'); 
      kindSelect1.val(null).trigger('change'); 

      parentsFieldSet.addClass('d-none'); // test if d-none exist and set


    }

  });


});



const convertDateToISO = (inputDate="") => {
  // Divisez la date en ses composants
  let dateParts = inputDate.split('/');

  //console.log("fund_id", fund_id);
  //console.log("short_flow", short_flow);

  if (dateParts.length !== 3) {
    return "Format de date invalide. Utilisez le format dd/mm/yyyy.";
  }

  // Assurez-vous que les composants de la date sont des nombres
  let day   = parseInt(dateParts[0]);
  let month = parseInt(dateParts[1]);
  let year  = parseInt(dateParts[2]);

  // Créez un objet Date en utilisant les composants
  let date = new Date(year, month - 1, day); // Mois - 1 car les mois sont indexés à partir de 0

  // Obtenez les composants de la date au format ISO
  let isoDate = date.toISOString().slice(0, 10);

  return isoDate;
}


const getAge = (birthday_date) => {

  //document.location.href = next_url

  var birthDay = new Date(birthday_date);
  var dateNow  = new Date();
  var diff     = dateNow-birthDay;
  var ageDate  = new Date(diff);
  var age      = Math.abs(ageDate.getUTCFullYear() - 1970);

  return age;
}




// birthdayCheck(e) {

//   e.preventDefault();

//   var date     = e.target.value;
//   console.log(date);
//   var birth    = convertDateToISO(date);
//   console.log(birth);
//   var birthDay = new Date(birth);
//   var age      = getAge(birthDay);
//   console.log("age", age);

//   if (age < 18) {
//     // lancer le rendu et le mettre a la place de l'ID
//   } else {
//     // fermé le contenu de l'ID en quesiton 
//   }

//   this.element.src = "/"



// }




