import { resetDestroyCheckBox, showCardOnLoad, destroyCard, showFirstHiddenCard } from "../documents.js";

$(document).ready(() => {
  if ($('.natural-step9').length) {
    const addDocument = $('.add-source-of-funds');
    const destroyDocument = $('.delete-document');

    resetDestroyCheckBox();
    showCardOnLoad(addDocument);
    addDocument.on('click', e => showFirstHiddenCard($(e.target)));
    destroyDocument.on('click', e => destroyCard($(e.target), '.add-source-of-funds'));
  }
});
