import { formatter } from '../../src/javascript/phoneNumberFormatter.js'

$(document).ready(() => {
  const [phoneInput, phoneErrorMessage] = [
    document.querySelector("#distributor_phone_number"),
    document.querySelector("#distributor_phone_number_error_msg")
  ]

  if(phoneInput) formatter(phoneInput, phoneErrorMessage);
});
