import { showNumberInputsOnLoad } from '../../../../application';

const showOtherInput = input => {
  const otherInputParent = input.parent('.row');

  otherInputParent.removeClass('d-none');
};

const hideOtherInput = input => {
  const otherInputParent = input.parent('.row');

  otherInputParent.addClass('d-none');
  input.val('');
};




function currency_pm_2_change_step7() {

  const select_currency = {
    'fr': {
      'CHF': { 'code': 'CHF', 'name': 'Francs Suisses', 'apost': 'de francs suisses' },
      'EUR': { 'code': '€', 'name': 'Euros', 'apost': 'd\'euros' },
      'GBP': { 'code': '£', 'name': 'Livre sterling', 'apost': 'de livres sterling' },
      'USD': { 'code': '$', 'name': 'Dollar U.S.', 'apost': 'de dollars U.S.' }
    },
    'en': {
      'CHF': { 'code': 'CH', 'name': 'CHF', 'apost': 'CHF' },
      'EUR': { 'code': '€', 'name': 'Euros', 'apost': 'euros' },
      'GBP': { 'code': '£', 'name': 'Pounds sterling', 'apost': 'pounds of sterling' },
      'USD': { 'code': '$', 'name': 'U.S. dollar', 'apost': 'U.S. dollars' }
    }
  };

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const locale = urlParams.get('locale');

  $('#users_profile_legal_currency_pm_2').on('select2:select', function (e) {
    var currency = e.params.data.text;

    console.log(currency.text);

    var aposts = $('.currency_apost');
    var codes = $('.currency_code');
    var attacheds = $('.currency_attached');

    if (aposts) {
      console.log(select_currency[locale][currency]);
      $(aposts).text(select_currency[locale][currency].apost);
    }

    if (codes) {
      codes.each(function () {
        $(this).text(select_currency[locale][currency]['code']);
      });
    }

    if (attacheds) {
      attacheds.each(function () {
        $(this).text(select_currency[locale][currency]['attached']);
      });
    }

  });
}


$(document).ready(() => {
  const form = $('input[name="step"][value="7"]').parents('form[id*="legal"]');
  const showRadio = form.find('#users_profile_legal_total_assets_more_than_1_b');
  const hideRadio = form.find('input[type="radio"]:not([id$="_more_than_1_b"]):not([id$="_other"])');
  const otherInput = $('#users_profile_legal_total_assets_other');

  showNumberInputsOnLoad(otherInput);
  hideRadio.on('change', () => hideOtherInput(otherInput));
  showRadio.on('change', () => showOtherInput(otherInput));

  currency_pm_2_change_step7();
})
