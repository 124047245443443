import { Modal } from "bootstrap";
const toggleTextInput = (checkBox, input) => {
  const trueCheckBox = $(`#users_profile_natural_${checkBox}_true`);
  const falseCheckBox = $(`#users_profile_natural_${checkBox}_false`);

  trueCheckBox.on('change', () => {
    $(`#users_profile_natural_${input}`).parents('.form-group').removeClass('d-none');
  });

  falseCheckBox.on('change', () => {
    $(`#users_profile_natural_${input}`).parents('.form-group').addClass('d-none');
  });
};

/**
 * The following function uses vanilla JS to toggle the modal
 * See {@link https://getbootstrap.com/docs/5.0/components/modal/#via-javascript}
 * Also, check out {@link https://getbootstrap.com/docs/4.0/components/modal/#modalshow}
*/
const handleProCatModalDisplay = () => {
  const proTrueCheckBox = document.querySelector('#users_profile_natural_request_pro_category_true');
  const proCatModalElement = document.querySelector('#proCatModal');
  const proCatModal = new Modal(proCatModalElement);
  proTrueCheckBox.addEventListener('change', () => {
    if (proCatModal)  proCatModal.show();
  })
}

const toggleWarningMessage = () => {
  const european = ['BE', 'BG', 'CZ', 'DK', 'DE', 'EE', 'IE', 'EL', 'ES', 'HR', 'IT', 'CY', 'LV', 'LT', 'LU', 'HU', 'MT', 'NL', 'AT', 'PL', 'PT', 'RO', 'SI', 'SK', 'FI', 'SE'];
  const overseas_fr = ['FR', 'GP', 'MQ', 'GY', 'RE', 'YT', 'PF'];
  //const checkBoxes = $("input[id^='users_profile_natural_has_']").not("input[id*='request_pro_category']").not("input[id*='has_been_pro_fia']");

  const checkBoxes = $("input[id^='users_profile_natural_has_'][class~='pro_cat_level_1']");

  // has_exercised_financial_profession
  // has_half_million_financial_instruments_portfolio 
  // has_financial_commitments

  const data = $('#profile-fiscal-country').data();
  const fiscalCountry = data ? data.fiscalCountry : null;

  // if (fiscalCountry !== null && fiscalCountry !== "CH") {


  if (checkBoxes.length && fiscalCountry) {
    checkBoxes.on('change', () => {
      let checkedCheckBoxes = $("input[id^='users_profile_natural_has_'][class~='pro_cat_level_1'][type!='hidden']:checked");
      let trueCheckBoxes = $("input[id^='users_profile_natural_has_'][class~='pro_cat_level_1'][type!='hidden'][id$='_true']:checked");

      if (checkedCheckBoxes.length === 3) {
        if (trueCheckBoxes.length >= 2) {
          $('#professional_client_warning').addClass('d-none');
          $('#french_non_professional_client_warning').removeClass('d-none');

          if (european.indexOf(fiscalCountry) !== -1 || overseas_fr.includes(fiscalCountry)) {
            $('#request_pro_cat_form').removeClass('d-none');
            handleProCatModalDisplay();
          }
        } else {
          $('#professional_client_warning').removeClass('d-none');
          $('#french_non_professional_client_warning').addClass('d-none');

          if (european.indexOf(fiscalCountry) !== -1 || overseas_fr.includes(fiscalCountry)) {
            $('#request_pro_cat_form').addClass('d-none');
          }
        }
      }

    })
  }

  // }

};

$(document).ready(() => {
  const input = $('#profile_financial_profession_details-invalid-feedback');

  if (input.text().length > 0) {
    input.parents('.form-group').removeClass('d-none');
  }

  toggleTextInput(
    'has_exercised_financial_profession',
    'financial_profession_details'
  );

  toggleWarningMessage();
});
